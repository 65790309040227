import { nanoid } from "nanoid";

// HEAD DATA
export const headData = {
  title: "laundry-homes", // e.g: 'Name | Developer'
  lang: "ko", // e.g: en, es, fr, jp
  description: "런드리홈즈에 오신걸 환영합니다.", // e.g: Welcome to my website
};

// HERO DATA
export const heroData = {
  title: "",
  name: "",
  subtitle: " ",
  cta: "",
  playstore: "",
  appsotre: "",
};

// ABOUT DATA
export const aboutData = {
  img: "",
  paragraphOne: "",
  paragraphTwo: "",
  paragraphThree: "",
  resume: "", // if no resume, the button will not show up
};

// PROJECTS DATA
export const projectsData = [
  {
    id: nanoid(),
    img: "mockup.png",
    title: "One Stop Service",
    info: "수거, 세탁, 배송을 한번에!",
    info2:
      "할 일도 많은데 세탁까지 하실건가요? 터치 한번으로 수거부터 배송까지. 세탁에 투자하는시간 3초 이제는 세탁도 모바일로.",
    // url: "",
    // repo: "https://github.com/cobidev/react-simplefolio", // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: "service_map.png",
    title: "Service Area",
    info: "점점 더 확대되는 런드리홈즈 지역!",
    info2: "수원(전지역), 화성(반월동,기산동,석우동,진안동,병점동), 용인(서비스 준비중)",
    // url: "",
    // repo: "https://github.com/cobidev/react-simplefolio", // if no repo, the button will not show up
  },
  // {
  //   id: nanoid(),
  //   img: "pro3.jpeg",
  //   title: "제목3",
  //   info: "",
  //   info2: "",
  //   url: "",
  //   repo: "https://github.com/cobidev/react-simplefolio", // if no repo, the button will not show up
  // },
];

// CONTACT DATA
export const contactData = {
  cta: "",
  btn: "",
  email: "",
};

// FOOTER DATA
export const footerData = {
  networks: [
    {
      id: nanoid(),
      name: "twitter",
      url: "",
    },
    {
      id: nanoid(),
      name: "codepen",
      url: "",
    },
    {
      id: nanoid(),
      name: "linkedin",
      url: "",
    },
    {
      id: nanoid(),
      name: "github",
      url: "",
    },
  ],
};

// Github start/fork buttons
export const githubButtons = {
  isEnabled: true, // set to false to disable the GitHub stars/fork buttons
};
